export default {
	SAVE_PAYMENT: 'companies/:company_id/payments/',
	GET_APPLIED_PAYMENTS: 'companies/:company_id/payments/apply/',
	APPLY_PAYMENT: 'companies/:company_id/payments/apply-payment/',
	UNAPPLY_PAYMENT: 'companies/:company_id/payments/unapply-payment/',
	SAVE_AUTOPAY_CONFIG: 'companies/:company_id/payments/save-autopay-config/',
	GET_PAYMENT: 'companies/:company_id/payments/',
	PAYMENT_METHOD: 'companies/:company_id/payments/payment-method/',  /* refactor */
	PAYMENTS: 'companies/:company_id/payments/',
	EMAIL_REFUND: 'companies/:company_id/payments/',
	UPLOAD_PAYROLL: 'companies/:company_id/direct-refund/upload-payroll',
	DOWNLOAD_PAYROLL_TEMPLATE: 'companies/:company_id/direct-refund/download-payroll-template'
}
